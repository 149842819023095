import React from 'react';
import Layout from '../components/layout';

class Contact extends React.Component {
	render() {
		return (
			<Layout />
		);
	}
}

export default Contact;